import React, { useState } from 'react';
import './App.css';
import { BallTriangle } from 'react-loading-icons';

function App() {
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showSignupPopup, setShowSignupPopup] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    setShowResults(true);

    // Simulating a delay to show the loading state
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const renderResults = () => {
    if (loading) {
      return (
        <div className="loading-box">
          <BallTriangle />
          <p>Searching the Litter Box...</p>
        </div>
      );
    }

    return (
      <div className="results-box bento-box">
        <h2>Results</h2>
        {/* Placeholder for search results */}
      </div>
    );
  };

  const handleLogin = () => {
    setShowLoginPopup(true);
  };

  const handleSignup = () => {
    setShowSignupPopup(true);
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    // Retrieve login form data here
    // ...

    // Perform login logic with the entered data
    // ...

    setShowLoginPopup(false);
  };

  const handleSignupSubmit = (event) => {
    event.preventDefault();
    // Retrieve signup form data here
    // ...

    // Perform signup logic with the entered data
    // ...

    setShowSignupPopup(false);
  };

  const handleClosePopup = () => {
    setShowLoginPopup(false);
    setShowSignupPopup(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="title">KittenFindr</h1>
        <div className="top-right-buttons">
          <button className="login-button" onClick={handleLogin}>Login</button>
          <button className="signup-button" onClick={handleSignup}>Sign Up</button>
        </div>
        <div className="search-box bento-box">
          <h2>Find Your Purr-fect Match</h2>
          <input type="text" placeholder="Enter your preferences" />
          <button onClick={handleSearch}>Search</button>
        </div>
        {showResults && renderResults()}
        <div className="testimonial-box bento-box">
          <h2>Paw-sitive Testimonials</h2>
          <div className="testimonial">
            <p>"I found my meow-tch on KittenFindr, and we're purr-fectly happy together!" - Whiskers</p>
          </div>
          <div className="testimonial">
            <p>"Thanks to KittenFindr, I met the love of my nine lives. Furrever grateful!" - Mittens</p>
          </div>
        </div>
        <div className="cat-gallery bento-box">
          <h2>Purr-fectly Adorable Cats</h2>
          <div className="cat-image">
            <img src="https://cdn-prd.content.metamorphosis.com/wp-content/uploads/sites/6/2022/12/shutterstock_781327003-1.jpg" alt="Cat" />
          </div>
          <div className="cat-image">
            <img src="https://t4.ftcdn.net/jpg/05/62/99/31/360_F_562993122_e7pGkeY8yMfXJcRmclsoIjtOoVDDgIlh.jpg" alt="Cat" />
          </div>
          <div className="cat-image">
            <img src="https://hips.hearstapps.com/hmg-prod/images/funny-cat-captions-1563551842.jpg" alt="Cat" />
          </div>
        </div>
        {showLoginPopup && (
          <div className="popup-bento-box">
            <button className="close-button" onClick={handleClosePopup}>&times;</button>
            <h2>Login</h2>
            <form onSubmit={handleLoginSubmit}>
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" required />
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" required />
              <button type="submit">Login</button>
            </form>
          </div>
        )}
        {showSignupPopup && (
          <div className="popup-bento-box">
            <button className="close-button" onClick={handleClosePopup}>&times;</button>
            <h2>Sign Up</h2>
            <form onSubmit={handleSignupSubmit}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" required />
              <label htmlFor="birthday">Birthday:</label>
              <input type="text" id="birthday" required />
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" required />
              <label htmlFor="signup-username">Username:</label>
              <input type="text" id="signup-username" required />
              <label htmlFor="signup-password">Password:</label>
              <input type="password" id="signup-password" required />
              <button type="submit">Sign Up</button>
            </form>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;

